.bg-dua-top {
  background: url("../../../../public/img/banner_dua_top_moi_ban.png") no-repeat
    0 0 / 100%;

  height: 148.2984375vw;
}

.bg-dua-top-mobile {
  display: none;
  background: url("../../../../public/img/banner_dua_top_moi_ban_mobile.png")
    no-repeat 0 0 / 100%;

  height: 386.53333333333336vw;
}

@media (max-width: 750px) {
  .bg-dua-top {
    display: none;
  }

  .bg-dua-top-mobile {
    display: block;
  }
}
