.switch-container {
  position: relative;
  display: inline-block;
  width: 62px;
  height: 32px;
}

.switch-container input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-class {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #efefff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-class:before {
  position: absolute;
  z-index: 9;
  content: "";
  height: 25px;
  width: 25px;
  left: 3px;
  bottom: 3px;
  background-color: #7673e6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .switch-class {
  background-color: #2c2947;
}

input:checked + .switch-class:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

/* Rounded switch-classs */
.switch-class.round {
  border-radius: 20px;
}

.switch-class.round:before {
  border-radius: 50%;
}
.sidebar_link {
  height: 52px;
  width: 55px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .sidebar_link:hover {
  background-color: var(--bg-button);
} */

.sidebar_link:hover .icon-fill path {
  fill: var(--main-01);
}

.dark .sidebar_link:hover .icon-fill path {
  fill: white;
}

.sidebar_link:hover .icon-stroke path,
.sidebar_link:hover .icon-stroke ellipse,
.sidebar_link:hover .icon-stroke circle {
  stroke: var(--main-01);
}

.dark .sidebar_link:hover .icon-stroke path,
.dark .sidebar_link:hover .icon-stroke ellipse,
.dark .sidebar_link:hover .icon-stroke circle {
  stroke: white;
}

.sidebar_link--active {
  min-width: 150px;
  padding: 20px 0;
  height: 52px;
  color: var(--main-01);
  display: flex;
  gap: 8px;
  justify-content: center;
  /* background-color: var(--bg-button); */
  border-radius: 100px;
}

.navigation-tab-overlay {
  border-radius: 100px;
  background-color: var(--bg-button);
  height: 52px;
  width: 150px;
  position: absolute;
  transition: 300ms;
}

.dark .navigation-tab-overlay {
  background-color: var(--main-01);
}

@media (max-width: 1023px) {
  .sidebar_link--active {
    min-width: 122px;
    height: 40px;
  }

  .sidebar_link {
    height: 40px;
  }

  .navigation-tab-overlay {
    height: 40px;
    width: 122px;
  }
}

@media (max-width: 767px) {
  .sidebar_link--active {
    border-radius: 0;
    background-color: transparent;
    min-width: 0;
    padding: 0;
  }
  .sidebar_link {
    border-radius: 0;
    padding: 0;
  }
  .sidebar_link:hover {
    background-color: transparent;
  }
}
