/* .reward-point {
  background: url("../../public/img/bg-point-reward.png") no-repeat top left /
    100%;
  position: relative;
  height: 93px;
}
.reward-point:after {
  content: "";
  display: block;
  position: absolute;
  width: 90px;
  height: 63px;
  background: url("../../public/img/img-points.png") no-repeat top left / 100%;
  top: -10px;
  right: -5px;
}

@media (max-width: 640px) {
  .reward-point {
    background: url("../../public/img/bg-point-mob.png");
    background-size: cover;
    padding-top: 0;
    padding-bottom: 10px;
    position: relative;
    height: 110px;
  }
  .reward-point:after {
    content: "";
    display: block;
    position: absolute;
    width: 90px;
    height: 63px;
    background: url("../../public/img/img-points.png") no-repeat top left / 100%;
    top: -10px;
    right: -5px;
  }
} */

.card-reward[data-activeid="active"]:hover {
  background: #907ed9;
  transition: 0.3s;
}

.card-reward[data-activeid="active"]:hover .text-point {
  color: white;
}

.card-reward {
  transition: 0.3s;
}

.spin-bg {
  background: url("../../../../public/img/spin/bg-spin.png") no-repeat top
    center / cover;
}

.bg-button-1 {
  background: url("../../../../public/img/spin/bg-button-1.png") no-repeat top
    center / cover;
}
.bg-button-2 {
  background: url("../../../../public/img/spin/bg-button-2.png") no-repeat top
    center / cover;
}

.scroll-text {
  /* animation properties */
  display: flex;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 20s linear infinite;
  -webkit-animation: my-animation 20s linear infinite;
  animation: my-animation 20s linear infinite;
}

.scroll-text p {
  white-space: nowrap;
  position: relative;
  display: flex;
  gap: 5px;
  align-items: center;
}

.scroll-text p::after {
  position: absolute;
  content: "";
  right: -13px;
  top: 50%;
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background: #ca34de;
}

.text-spin {
  font-weight: 900;
  font-size: 29px;
  line-height: 35px;
  text-transform: uppercase;
  background: linear-gradient(180deg, #fff280 0%, #faff00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.text-button-modal {
  background: linear-gradient(92deg, #ce9dc9 1.05%, #a690e0 112.87%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.point-filter:hover {
  background: #efefff;
}

.point-filter:hover > svg > path {
  fill: #6945c2;
}

@media (max-width: 576px) {
  .luckySpin {
    transform: scale(0.7);
    transform-origin: 50% 50%;
  }
}

.bg-white-blur {
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3.5px);
  border-radius: 10px;
}

.bg-violet-blur {
  box-sizing: border-box;
  background: rgba(22, 10, 48, 0.6);
  backdrop-filter: blur(3.5px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 10px;
  border: 1px solid;
  border-image-source: linear-gradient(
    92.59deg,
    rgba(255, 255, 255, 0) 9.84%,
    rgba(255, 255, 255, 0.1) 92.1%
  );
}

.bg-violet-blur * {
  color: #fff;
}
.item-action {
  bottom: -1px;
  right: -1px;
}
.item-action button {
  width: 100px;
  height: 32px;
  background-color: transparent;
  border: none;
  outline: none;
}

.item-action button .bg-btn-buy {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(../../../../public/img/bg-button-buy.png) no-repeat top left /
    100%;
  opacity: 0.7;
}

@media (max-width: 640px) {
  .item-action button {
    width: 49px;
    height: 30px;
  }
  .item-action button .bg-btn-buy {
    background: url(../../../../public/img/bg-btn-buy-mob.png) no-repeat top
      left / 100%;
  }
  .bg-white-blur {
    padding: 8px;
  }
}

.item-action:hover button .bg-btn-buy {
  opacity: 1;
}

.gift-item:hover .btn-preview-gift {
  display: flex;
}

.custom-swiper .swiper-pagination-bullet {
  background: #fff !important;
}
.custom-swiper .swiper-pagination-bullet-active {
  background-color: #907ed9 !important;
}

.hidden-scroll ::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
  background: black;
}

.hidden-scroll ::-webkit-scrollbar-track {
  width: 0px !important;
  height: 0px !important;
  background: transparent;
  border-radius: 10px;
}

.hidden-scroll ::-webkit-scrollbar-thumb {
  background: var(--input-border);
  border-radius: 10px;
}

.spin-modal .ReactModal__Content--after-open {
  max-width: 580px;
  width: 100%;
  margin: 0 auto;
  border: none !important;
  outline: none;
  background: transparent !important;
  background-repeat: no-repeat !important;
  position: fixed;
  top: 45% !important;
  left: 50% !important;
  transform: translate(-50%, -45%);
}

.result-spin-modal .ReactModal__Content--after-open {
  max-width: 580px;
  width: 100%;
  border: none !important;
  outline: none;
  background: transparent !important;
  background-repeat: no-repeat !important;
  position: fixed;
  top: 45% !important;
  left: 50% !important;
  transform: translate(-50%, -45%);
  padding: 0 !important;
}

.error-spin-modal .ReactModal__Content--after-open {
  max-width: 500px;
  width: 100%;
  border: none !important;
  outline: none;
  background: transparent !important;
  background-repeat: no-repeat !important;
  position: fixed;
  top: 45% !important;
  left: 50% !important;
  transform: translate(-50%, -45%);
  padding: 0 !important;
}

.rule-spin-modal .ReactModal__Content--after-open {
  max-width: 668px;
  width: 100%;
  border: none !important;
  outline: none;
  background: transparent !important;
  background-repeat: no-repeat !important;
  position: fixed;
  top: 45% !important;
  left: 50% !important;
  transform: translate(-50%, -45%);
  padding: 0 !important;
}

@media screen and (max-width: 991px) {
  .error-spin-modal .ReactModal__Content--after-open {
    max-width: unset;
    width: auto;
    height: unset;
    padding: 0 !important;
  }
  .spin-modal .ReactModal__Content--after-open {
    max-width: unset;
    width: auto;
    height: unset;
    min-height: unset;
    padding: 0 !important;
  }
  .result-spin-modal .ReactModal__Content--after-open {
    max-width: unset;
    width: auto;
    height: unset;
    min-height: unset;
    padding: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .error-spin-modal .ReactModal__Content--after-open {
    width: 90%;
    padding: 0 !important;
  }
  .result-spin-modal .ReactModal__Content--after-open {
    width: 100%;
    padding: 0 !important;
  }
  .spin-modal .ReactModal__Content--after-open {
    width: 100%;
    padding: 0 !important;
  }
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 9999999;
}

.ReactModal__Content .ReactModal__Content--after-open {
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
  background: var(--block-color) !important;
  border-radius: 15px !important;
  max-height: 700px !important;
  inset: unset !important;
  position: fixed;
  top: 45% !important;
  left: 50% !important;
  transform: translate(-50%, -45%);
}

.lixi-modal .ReactModal__Content--after-open {
  max-width: 662px;
  width: 100%;
  min-height: 447px;
  border: none !important;
  outline: none;
  background: transparent !important;
  background-repeat: no-repeat !important;
  max-height: 700px !important;
  inset: unset !important;
  position: fixed;
  top: 45% !important;
  left: 50% !important;
  transform: translate(-50%, -45%);
}
.lixi-other .ReactModal__Content--after-open {
  max-width: 500px;
  width: 100%;
  min-height: 447px;
  border: none !important;
  outline: none;
  background: transparent !important;
  background-repeat: no-repeat !important;
  max-height: 700px !important;
  inset: unset !important;
  position: fixed;
  top: 45% !important;
  left: 50% !important;
  transform: translate(-50%, -45%);
}
