.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #faf8ff;
  opacity: 1;
}

.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet-active {
  background: #7673e5;
  width: 32px;
}

@media (max-width: 576px) {
  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
  }

  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet-active {
    width: 16px;
  }
}

.cart-container {
  transition: 200ms cubic-bezier(0.87, -0.41, 0.19, 1.44);
}

.cart-text {
  transition: 200ms cubic-bezier(0.87, -0.41, 0.19, 1.44);
}

.cart-container:hover .cart-text {
  transform: translate(0, -50%) !important;
}

.svg-stroke:active svg path {
  stroke: #dad9fe;
}

.svg-fill:active svg path {
  fill: #dad9fe;
}

.data-method:hover div {
  background: #ececee;
}

.dark .data-method:hover div {
  background: #ececee1a;
}

.data-method:hover p {
  color: #7673e5;
}
