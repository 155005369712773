.pagination-container {
  display: flex;
  list-style-type: none;
}

.pagination-item {
  padding: 0 8px;
  height: 32px;
  text-align: center;
  /* color: rgba(0, 0, 0, 0.87); */
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-size: 13px;
  min-width: 32px;
}

.pagination-item .dots:hover {
  background-color: transparent;
  cursor: default;
}
.pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: default;
}

.pagination-item .selected {
  background-color: rgba(0, 0, 0, 0.08);
}
