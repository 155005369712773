.banner-store-detail {
  background-image: url("../../../public/img/store/store-detail-banner.png");
  background-repeat: no-repeat;

  background-size: 100% 100%;
}

.giftcode-banner {
  background-image: url("../../../public/img/store/banner_giftcode.png");
  background-repeat: no-repeat;

  background-size: 100% 100%;
}

.bg-qua-tro-luc {
  background-image: url("../../../public/img/store/bg_qua_tro_luc.png");
  background-repeat: no-repeat;

  background-size: 100% 100%;
}

.bg-qua-tro-luc-image {
  background-image: url("../../../public/img/qua-tro-luc.png");
  background-repeat: no-repeat;

  background-size: 100% 100%;
}

.text-transparent {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.8) -1.66%,
    rgba(255, 255, 255, 0.6) 59.04%,
    rgba(255, 255, 255, 0.2) 108.51%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  backdrop-filter: blur(2px);
}

.giftcode-list .swiper {
  overflow: scroll;
}

.border-giftcode::before {
  position: absolute;
  content: "";
  top: -6px;
  left: -6px;
  width: 12px;
  height: 12px;
  background: var(--bg-color);
  border-radius: 12px;
  border: 1px solid attr(data-activeid);
}
/* .dark .border-giftcode::before,
.dark .border-giftcode::after {
  background: #1d1933;
} */

.border-giftcode[data-activeid="#0000001A"]::before {
  border: 1px solid #0000001a;
}

.border-giftcode[data-activeid="#ffffff1a"]::before {
  border: 1px solid #ffffff1a;
}

.border-giftcode::after {
  position: absolute;
  content: "";
  bottom: -6px;
  left: -6px;
  width: 12px;
  height: 12px;
  background: var(--bg-color);
  border-radius: 12px;
  border: 1px solid attr(data-activeid);
}

.border-giftcode[data-activeid="#0000001A"]::after {
  border: 1px solid #0000001a;
}
.border-giftcode[data-activeid="#ffffff1a"]::after {
  border: 1px solid #ffffff1a;
}

.border-voucher::before {
  position: absolute;
  content: "";
  top: -6px;
  left: -6px;
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 12px;
}
.dark .border-voucher::before,
.dark .border-voucher::after {
  background: #1d1933;
}

.border-voucher[data-activeid="#7673E5"]::before {
  border: 1px solid #7673e5;
}

.border-voucher[data-activeid="#342E55"]::before {
  border: 1px solid #342e55;
}

.border-voucher[data-activeid="#afafaf"]::before {
  border: 1px solid #afafaf;
}

.border-voucher[data-activeid="#e2e2e2"]::before {
  border: 1px solid #e2e2e2;
}

.border-voucher::after {
  position: absolute;
  content: "";
  bottom: -6px;
  left: -6px;
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 12px;
  border: 1px solid attr(data-activeid);
}

.border-voucher[data-activeid="#7673E5"]::after {
  border: 1px solid #7673e5;
}

.border-voucher[data-activeid="#afafaf"]::after {
  border: 1px solid #afafaf;
}

.border-voucher[data-activeid="#e2e2e2"]::after {
  border: 1px solid #e2e2e2;
}

.border-voucher[data-activeid="#342E55"]::after {
  border: 1px solid #342e55;
}

.rotate {
  animation: rotate 1s linear 0s infinite;
  -webkit-animation: rotate 1s linear 0s infinite;
  -moz-animation: rotate 1s linear 0s infinite;
  -o-animation: rotate 1s linear 0s infinite;
}

.rotate-back {
  animation: rotate-back 1s linear 0s infinite;
  -webkit-animation: rotate-back 1s linear 0s infinite;
  -moz-animation: rotate-back 1s linear 0s infinite;
  -o-animation: rotate-back 1s linear 0s infinite;
}

.result-payment-container {
  min-height: calc(100vh - 84px);
}

.scale-card:hover img {
  transform: scale(1.03);
}

.scale-card img {
  transform: scale(1);
}

.border-voucher-icon::after {
  position: absolute;
  content: "";
  top: 8px;
  right: -18px;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 10px;
  border: 1px solid #7673e5;
}

.border-voucher-icon::before {
  position: absolute;
  content: "";
  top: 8px;
  left: -18px;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 10px;
  border: 1px solid #7673e5;
}
.dark .border-voucher-icon::before,
.dark .border-voucher-icon::after {
  background: #1d1933;
}

@media (max-width: 767px) {
  .result-payment-container {
    min-height: calc(100vh - 148px);
  }

  .giftcode-banner {
    background-image: none;
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

@keyframes rotate-back {
  from {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
}
