.range-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 8px;
    background: #F0F2F5;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
    border-radius: 8px;
}

.range-slider:hover {
    opacity: 1;
}

.range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #7673E5;
    cursor: pointer;
    border-radius: 50%;
}

.range-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #7673E5;
    cursor: pointer;
    border-radius: 50%;
}

.range-slider-value {
    margin-top: 10px;
    font-size: 18px;
}