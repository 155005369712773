@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@import "fonts.css";
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800&display=swap");
@import "../components/header/style.module.css";
@import "../containers/game/style.module.css";
@import "../containers/store/style.css";
@import "../containers/merchandise/style.module.css";
@import "../components/pagination/style.module.css";
@import "../components/multi-range-slider/style.module.css";
@import "../containers/reward-center/lucky-spin/style.css";
@import "../containers/event/dua-top-moi-ban/style.module.css";
@import "../containers/reward-center/lixi/style.module.css";
@import "../containers/member-ship/style.mudule.css";

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    /* playee app color variable  */
    --primary-text: #100d28;
    --bg-color: #f9f9f9;
    --input-border: #f0f2f5;
    --main-01: #7673e5;
    --bg-button: #efefff;
    --bg-header: #ffffff;
    --block-color: #ffffff;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;

    /* playee app color variable  */
    --primary-text: #faf8ff;
    --bg-color: #1a1925;
    --input-border: #24203f;
    --main-01: #7673e5;
    --bg-button: #efefff;
    --bg-header: #1a173b;
    --block-color: #1d1933;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

@layer components {
  .dark .inputDark:-webkit-autofill {
    color: #fff !important;
    -webkit-text-fill-color: #fff !important;
  }
}

html,
body {
  touch-action: manipulation;
  padding: 0;
  margin: 0;
  color: var(--primary-text);
  font-family: "Nunito-400";
  background: var(--bg-color);
  min-height: 100vh;
}

.Nunito-800 {
  font-family: Nunito-800, serif !important;
}

.Nunito-700 {
  font-family: Nunito-700, serif !important;
}

.Nunito-600 {
  font-family: Nunito-600, serif !important;
}

.Nunito-500 {
  font-family: Nunito-500, serif !important;
}

.Nunito-400 {
  font-family: Nunito-400, serif !important;
}

.Nunito-300 {
  font-family: Nunito-300, serif !important;
}

.Philosopher-400 {
  font-family: Philosopher-400, serif !important;
}

.Philosopher-700 {
  font-family: Philosopher-700, serif !important;
}

.Pacifico-400 {
  font-family: Pacifico-400, serif !important;
}

.Montserrat-400 {
  font-family: Montserrat-400, serif !important;
}

.Montserrat-500 {
  font-family: Montserrat-500, serif !important;
}

.Montserrat-700 {
  font-family: Montserrat-700, serif !important;
}

.Montserrat-800 {
  font-family: Montserrat-800, serif !important;
}

/* Clear default background when using saved account */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

html ::-webkit-scrollbar,
body ::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
  background: var(--bg-color);
}

html ::-webkit-scrollbar-track,
body ::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

html ::-webkit-scrollbar-thumb,
body ::-webkit-scrollbar-thumb {
  background: var(--input-border);
  border-radius: 10px;
}

.dot-flashing {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #7673e5;
  margin: 0 12px;
  animation: animate-dot-flashing 1s infinite alternate;
}

.dot-flashing:nth-child(1) {
  animation-delay: 0s;
}

.dot-flashing:nth-child(2) {
  animation-delay: 0.2s;
}

.dot-flashing:nth-child(3) {
  animation-delay: 0.4s;
}

.dot-flashing:nth-child(4) {
  animation-delay: 0.6s;
}

.dot-flashing:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes animate-dot-flashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: #dad9fe;
  }
}

.dot-flashing-landing {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #f45e5e;
  margin: 0 12px;
  animation: animate-dot-flashing-landing 1s infinite alternate;
}

.dot-flashing-landing:nth-child(1) {
  animation-delay: 0s;
}

.dot-flashing-landing:nth-child(2) {
  animation-delay: 0.2s;
}

.dot-flashing-landing:nth-child(3) {
  animation-delay: 0.4s;
}

.dot-flashing-landing:nth-child(4) {
  animation-delay: 0.6s;
}

.dot-flashing-landing:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes animate-dot-flashing-landing {
  0% {
    background-color: #f45e5e;
  }
  50%,
  100% {
    background-color: #fff;
  }
}

.hidden-scroll-bar ::-webkit-scrollbar {
  width: 0px;
  height: 0px !important;
  background: transparent;
}

.hidden-scroll-bar ::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.hidden-scroll-bar ::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

.ql-toolbar {
  display: flex;
  align-items: center;
  border: 0 !important;
  padding: 32px 12px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.ql-toolbar-fixed {
  display: flex;
  align-items: center;
  border: 0 !important;
  padding: 16px !important;
}

@media (max-width: 768px) {
  .ql-toolbar {
    display: flex;
    align-items: center;
    border: 0 !important;
    padding: 24px 12px !important;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.ql-container {
  font-size: 16px !important;
  border: 0 !important;
  padding: 0 !important;
}

.ql-editor {
  padding: 0 !important;
}
.ql-stroke {
  strokewidth: 1 !important;
}

.ql-toolbar button,
.ql-icon-picker {
  width: 32px !important;
  height: 32px !important;
}
.ql-toolbar-fixed button,
.ql-toolbar-fixed button svg {
  width: 26px !important;
  height: 26px !important;
}
.ql-align-center {
  text-align: center;
}
.ql-formats {
  display: flex !important;
  align-items: center !important;
  margin: 0 !important;
  gap: 8px !important;
}
[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  color: #706e7e;
}

.dark .ql-stroke {
  stroke: #fff !important;
}
.dark .ql-editor.ql-blank::before {
  color: #fff;
}
.rdp-dropdown_month,
.rdp-dropdown_year {
  display: none;
}
.bold-600--700 {
  font-family: Nunito-600, serif !important;
}

.bold-600--700:hover,
.bold-600--700:focus {
  font-family: Nunito-700, serif !important;
}

.user-help-center {
  transition: all 300ms cubic-bezier(0.87, -0.41, 0.19, 1.44);
}

.container-chart ::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

.privacy-policy strong {
  color: #c90000;
  font-family: Nunito-600, serif !important;
}

.privacy-policy br,
.term-service br {
  height: 20px;
}

.privacy-policy b {
  font-weight: 700;
  font-family: "Nunito-700";
}

/* react select 
  'react-select' represent classNamePrefix
*/
.react-select__control {
  border: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.react-select__control--is-focused {
  border: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  box-shadow: none !important;
  outline: 0 !important;
}
.react-select__multi-value {
  background-color: #f1f1fc !important;
  border-radius: 12px !important;
}
.react-select__multi-value__label {
  color: #7673e5 !important;
}
.react-select__multi-value__remove:hover {
  background-color: transparent !important;
}
.react-select__multi-value__remove svg {
  fill: #7673e5;
}
.react-select__option {
  padding: 8px 24px 8px 12px !important;
  border-radius: 12px !important;
  overflow: hidden !important;
}
.react-select__option--is-focused {
  color: #7673e5 !important;
  background-color: #f1f1fc !important;
}
.react-select__menu {
  border-radius: 0 0 16px 16px !important;
  border: 0;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.15) !important;
}
.react-select__menu-list {
  padding: 12px !important;
  max-height: 220px !important;
  border-radius: 0 0 16px 16px !important;
  border: 0;
  box-shadow: 0;
}
.react-select__clear-indicator {
  display: none !important;
}

.dark .react-select__control {
  border: 0 !important;
  border-bottom: 1px solid #b7b6bf !important;
  border-radius: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.dark .react-select__input {
  color: #fff !important;
}

.dark .react-select__menu-list {
  background-color: #30303a !important;
}
.dark .react-select__menu {
  background-color: #30303a !important;
}
.dark .react-select__option--is-focused {
  color: #7673e5 !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.dark .react-select__multi-value {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 12px !important;
}

.banner-swiper .swiper-pagination {
  /* transform: rotate(90deg); */
  top: 0px;
  right: 10px;
  height: 100%;
  width: 30px !important;
  left: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.other-page-style h1,
.other-page-style li,
.other-page-style p,
.other-page-style span,
.other-page-style h3,
.other-page-style h2,
.other-page-style h4 {
  background-color: transparent;
  background: transparent;
  /* font-family: "Nunito-400"; */
}

.dark .other-page-style h1,
.dark .other-page-style li,
.dark .other-page-style p,
.dark .other-page-style span,
.dark .other-page-style h3,
.dark .other-page-style h2,
.dark .other-page-style h4 {
  color: #faf8ff !important;
}

.dark .other-page-style td {
  border-color: #faf8ff !important;
}

.other-page-style c3 {
  font-family: "Nunito-700";
}

@media (max-width: 576px) {
  .banner-swiper
    .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet-active {
    height: 16px !important;
    width: 8px !important;
  }
}

@media (max-width: 768px) {
  .banner-swiper
    .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet-active {
    height: 22px;
    width: 12px;
  }
}

.number-arrow-disable::-webkit-outer-spin-button,
.number-arrow-disable::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

#thothan-chatbot-bubble-button {
  background-color: #091f7c !important;
}

@media (max-width: 767px) {
  #thothan-chatbot-bubble-button {
    bottom: 40px !important;
    right: 10px !important;
    width: 40px !important;
    height: 40px !important;
  }
}

.dot1,
.dot2,
.dot3,
.dot4,
.dot5 {
  right: 22%;
  top: 8%;
  width: 15px;
  height: 11px;
  position: absolute;
  background: url(/img/lixi/flower01.png);
  background-size: 100% 100%;
  z-index: 2;
}

.dot6,
.dot7,
.dot8,
.dot9,
.dot10 {
  right: 22%;
  top: 8%;
  width: 18px;
  height: 14px;
  position: absolute;
  background: url(/img/lixi/flower02.png);
  background-size: 100% 100%;
  z-index: 2;
}

.dot11,
.dot12,
.dot13,
.dot14,
.dot15 {
  right: 22%;
  top: 8%;
  width: 22px;
  height: 21px;
  position: absolute;
  background: url(/img/lixi/flower02.png);
  background-size: 100% 100%;
  z-index: 2;
}

@media (max-width: 576px) {
  .dot1,
  .dot2,
  .dot3,
  .dot4,
  .dot5 {
    width: 7.5px;
    height: 6px;
  }

  .dot6,
  .dot7,
  .dot8,
  .dot9,
  .dot10 {
    width: 9px;
    height: 7px;
  }

  .dot11,
  .dot12,
  .dot13,
  .dot14,
  .dot15 {
    width: 11px;
    height: 10px;
  }
}

.small-modal .ReactModal__Content--after-open {
  max-width: 320px;
}
