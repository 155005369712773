.membership_banner {
  background: url("../../../public/img/membership-banner.png") no-repeat 0 0 /
    100%;
  background-size: cover;
  background-position: center;
  height: 480px;
}

.text-transparent-membership {
  background: linear-gradient(90deg, #2179e8 -1.73%, #b653f9 67.94%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.border-membership::before {
  position: absolute;
  content: "";
  top: -8px;
  left: -6px;
  width: 16px;
  height: 16px;
  background: var(--bg-color);
  border-radius: 16px;
  border: 1px solid #7673e5;
}

.border-membership::after {
  position: absolute;
  content: "";
  bottom: -8px;
  left: -6px;
  width: 16px;
  height: 16px;
  background: var(--bg-color);
  border-radius: 16px;
  border: 1px solid #7673e5;
}

@media (max-width: 767px) {
  .membership_banner {
    height: 192px;
  }
  .border-membership::before,
  .border-membership::after {
    width: 12px;
    height: 12px;
    left: -4px;
  }

  .border-membership::before {
    top: -6px;
  }

  .border-membership::after {
    bottom: -6px;
  }
}
