.rotate-animation {
  /* transform: rotate(-50deg); */
  transition: transform 10s;
  animation: rotate-animation 10s linear infinite;
  transform-origin: top;
}

.lixi-animation {
  transition: transform 1s;
  animation: shake 1s ease-in-out infinite;
  transform-origin: top;
}

@keyframes rotate-animation {
  0% {
    transform: rotate(8deg);
  }

  50% {
    transform: rotate(-8deg);
  }

  100% {
    transform: rotate(8deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }

  5% {
    -webkit-transform: translate(-52.5%, -50%) rotate3d(0, 0, 1, -2.25deg);
    transform: translate(-52.5%, -50%) rotate3d(0, 0, 1, -2.25deg);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }

  10% {
    -webkit-transform: translate(-55%, -50%) rotate3d(0, 0, 1, -4.5deg);
    transform: translate(-55%, -50%) rotate3d(0, 0, 1, -4.5deg);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }

  15% {
    -webkit-transform: translate(-51.25%, -50%) rotate3d(0, 0, 1, -2.25deg);
    transform: translate(-51.25%, -50%) rotate3d(0, 0, 1, -2.25deg);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }

  20% {
    -webkit-transform: translate(-45%, -50%) rotate3d(0, 0, 1, 4deg);
    transform: translate(-45%, -50%) rotate3d(0, 0, 1, 4deg);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }

  25% {
    -webkit-transform: translate(-49%, -50%) rotate3d(0, 0, 1, -0.875deg);
    transform: translate(-49%, -50%) rotate3d(0, 0, 1, -0.875deg);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }

  30% {
    -webkit-transform: translate(-53%, -50%) rotate3d(0, 0, 1, -3.5deg);
    transform: translate(-53%, -50%) rotate3d(0, 0, 1, -3.5deg);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }

  35% {
    -webkit-transform: translate(-48%, -50%) rotate3d(0, 0, 1, -1.75deg);
    transform: translate(-48%, -50%) rotate3d(0, 0, 1, -1.75deg);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }

  40% {
    -webkit-transform: translate(-47%, -50%) rotate3d(0, 0, 1, 3deg);
    transform: translate(-47%, -50%) rotate3d(0, 0, 1, 3deg);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }

  45% {
    -webkit-transform: translate(-51%, -50%) rotate3d(0, 0, 1, 0.25deg);
    transform: translate(-51%, -50%) rotate3d(0, 0, 1, 0.25deg);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }

  50% {
    -webkit-transform: translate(-54%, -50%) rotate3d(0, 0, 1, -2.5deg);
    transform: translate(-54%, -50%) rotate3d(0, 0, 1, -2.5deg);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }

  55% {
    -webkit-transform: translate(-46.5%, -50%) rotate3d(0, 0, 1, 1.5deg);
    transform: translate(-46.5%, -50%) rotate3d(0, 0, 1, 1.5deg);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }

  60% {
    -webkit-transform: translate(-46%, -50%) rotate3d(0, 0, 1, 2deg);
    transform: translate(-46%, -50%) rotate3d(0, 0, 1, 2deg);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }


  70% {
    -webkit-transform: translate(-53%, -50%) rotate3d(0, 0, 1, -1.5deg);
    transform: translate(-53%, -50%) rotate3d(0, 0, 1, -1.5deg);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }

  75% {
    -webkit-transform: translate(-48.5%, -50%) rotate3d(0, 0, 1, -0.75deg);
    transform: translate(-48.5%, -50%) rotate3d(0, 0, 1, -0.75deg);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }

  80% {
    -webkit-transform: translate(-47%, -50%) rotate3d(0, 0, 1, 1deg);
    transform: translate(-47%, -50%) rotate3d(0, 0, 1, 1deg);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }

  85% {
    -webkit-transform: translate(-50.5%, -50%) rotate3d(0, 0, 1, -0.25deg);
    transform: translate(-50.5%, -50%) rotate3d(0, 0, 1, -0.25deg);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }

  90% {
    -webkit-transform: translate(-51%, -50%) rotate3d(0, 0, 1, -0.5deg);
    transform: translate(-51%, -50%) rotate3d(0, 0, 1, -0.5deg);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }

  95% {
    -webkit-transform: translate(-50%, -50%) rotate3d(0, 0, 1, 0.125deg);
    transform: translate(-50%, -50%) rotate3d(0, 0, 1, 0.125deg);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }

  100% {
    -webkit-transform: translate(-49%, -50%);
    transform: translate(-49%, -50%);
    transform-origin: center bottom;
    -webkit-transform-origin: center bottom;
  }
}