.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.slider {
  position: relative;
  width: 278px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 16px;
  height: 8px;
}

.slider__track {
  background-color: #ececee;
  width: 278px;
  z-index: 1;
}

.slider__range {
  background-color: #9f9ded;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #dee2e6;
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 278px;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #7673e5;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin-top: 6px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #7673e5;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

@media (max-width: 1139px) {
  .slider,
  .thumb,
  .slider__track {
    width: 308px;
  }
}

@media (max-width: 576px) {
  .slider,
  .thumb,
  .slider__track {
    width: 235px;
  }
}
