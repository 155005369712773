.game-banner {
  position: relative;
}

.dark .game-banner::before,
.dark .game-banner::after {
  content: "";
  position: absolute;
  border-radius: 730px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(148, 112, 237, 0.11) 0%,
    rgba(255, 255, 255, 0) 83.85%
  );
  width: 730px;
  height: 711px;
  flex-shrink: 0;
  z-index: 1;
}

.dark .game-banner::before {
  top: 30px;
  left: -300px;
}

.dark .game-banner::after {
  top: 60px;
  right: -300px;
}

.trending_card:hover .animate_buttons {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.trending_card:hover .animate_info {
  opacity: 0;
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px);
}

.animate_buttons {
  opacity: 0;
  -webkit-transform: translateY(15px);
  transform: translateY(15px);
  -webkit-transition: all 0.4s ease 0.25s;
  transition: all 0.4s ease 0.25s;
}

.animate_background {
  -webkit-transition: all 1s ease 0.5s;
  transition: all 1s ease 0.5s;
  animation: translate-background 1s;
}

.hover-icon:hover,
.dark .hover-icon:hover,
.hover-icon:active,
.dark .hover-icon:active,
.hover-icon:focus,
.dark .hover-icon:focus {
  background: var(--main-01);
}

.hover-icon:hover > svg path,
.dark .hover-icon:hover > svg path,
.hover-icon:focus > svg path,
.dark .hover-icon:focus > svg path,
.hover-icon:active > svg path,
.dark .hover-icon:active > svg path {
  stroke: white;
}

.game-detail-banner {
  height: 100vh;
  width: 100vw;
}

/* @media (max-width: 992px) {
  .game-detail-banner {
    height: 434px;
  }
}

@media (max-width: 576px) {
  .game-detail-banner {
    height: 200px;
  }
} */
@keyframes translate-background {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.CollapsibleContent {
  overflow: hidden;
}
.CollapsibleContent[data-state="open"] {
  animation: slideDown 300ms ease-out;
}
.CollapsibleContent[data-state="closed"] {
  animation: slideUp 300ms ease-out;
}

.icon-scroll-down {
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 1.5;
  width: 20px;
}

.icon-scroll-down path {
  fill: none;
  stroke: #fff;
  stroke-width: 20px;
}

.icon-scroll-down #wheel {
  animation: scroll ease 1.5s infinite;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(100px);
  }
}
