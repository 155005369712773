@font-face {
  font-family: "Nunito-300";
  src: url("../../public/font/Nunito-Light.ttf");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Nunito-400";
  src: url("../../public/font/Nunito-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Nunito-500";
  src: url("../../public/font/Nunito-Medium.ttf");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Nunito-600";
  src: url("../../public/font/Nunito-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Nunito-700";
  src: url("../../public/font/Nunito-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Nunito-800";
  src: url("../../public/font/Nunito-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Philosopher-400";
  src: url("../../public/font/Philosopher-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Philosopher-700";
  src: url("../../public/font/Philosopher-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Pacifico-400";
  src: url("../../public/font/Pacifico-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat-400";
  src: url("../../public/font/Montserrat-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat-500";
  src: url("../../public/font/Montserrat-Medium.ttf");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat-700";
  src: url("../../public/font/Montserrat-Bold.ttf");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat-800";
  src: url("../../public/font/Montserrat-ExtraBold.ttf");
  font-style: normal;
  font-weight: 800;
}
